





























































































































































































import { Getter } from 'vuex-class';
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import AuthModule from '@/app/auth/store';
import DatatableMixin from '@/app/core/mixins/datatable.mixin';
import ToDate from '@/app/core/components/to-date.vue';
import FromDate from '@/app/core/components/from-date.vue';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ApartmentSelect from '@/app/core/components/apartment-select.vue';
import ReservationChannel from './reservation-channel.vue';
import ListReservationsDto from '../dto/list-reservations.dto';
import ReservationDto, { ReservationType } from '../dto/reservation.dto';

@Component({
  components: {
    ToDate,
    FromDate,
    ApartmentSelect,
    ReservationChannel,
  },
})
export default class ReservationsDataTable extends Mixins(DatatableMixin, HandlesErrorMixin) {
  declare items: ReservationDto[];

  @Prop({ type: Object, required: true })
  filters!: ListReservationsDto;

  @Getter
  private isHost!: typeof AuthModule.prototype.isHost;

  get headers() {
    const headers = [
      {
        text: this.$t('label.apartment').toString(),
        value: 'apartment',
      },
      {
        text: this.$t('label.arrival').toString(),
        value: 'arrival',
      },
      {
        text: this.$t('label.departure').toString(),
        value: 'departure',
      },
      {
        text: this.$t('label.actions').toString(),
        value: 'actions',
        class: 'visible-on-mobile-only',
      },
    ];

    const guestHeader = {
      text: this.$t('label.guest').toString(),
      value: 'guest',
    };

    const channelHeader = {
      text: this.$t('label.channel').toString(),
      value: 'channel',
    };

    if (this.isHost) {
      headers.splice(1, 0, guestHeader);
      headers.splice(4, 0, channelHeader);
    }

    return headers;
  }

  isCancelled(reservation: ReservationDto) {
    return reservation.type === ReservationType.Cancellation;
  }

  isBlocking(item: ReservationDto) {
    return item.channel?.name === 'Blocked channel';
  }

  allowedToDates(date: string) {
    return date >= (this.filters?.from || '');
  }

  @Emit('filterByApartment')
  filterByApartment(apartmentId: string) {
    return apartmentId;
  }

  @Emit('updateFromDate')
  updateFromDate(from: string) {
    return from;
  }

  @Emit('updateToDate')
  updateToDate(to: string) {
    return to;
  }

  @Emit('cancel')
  onCancel(reservation: ReservationDto) {
    return reservation;
  }

  @Emit('delete')
  onDelete(reservation: ReservationDto) {
    return reservation;
  }
}
