














import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import ApartmentDto from '@/app/apartments/dto/apartment.dto';
import { getApartments } from '@/app/apartments/services/apartments.service';
import HandlesErrorMixin from '../mixins/handles-error.mixin';

@Component
export default class ApartmentSelect extends Mixins(HandlesErrorMixin) {
  loading = false;

  apartments: ApartmentDto[] = [];

  allOption = {
    id: '*',
    name: this.$t('label.allProperties').toString(),
  };

  @Prop({ type: String })
  value!: string;

  @Prop({ type: Boolean, default: false })
  showAllOption!: boolean;

  @Emit('input')
  input(value: string) {
    return value;
  }

  async getApartments() {
    this.loading = true;

    try {
      const res = await getApartments();
      this.apartments = res.data.items;

      if (this.showAllOption) {
        this.apartments = [
          this.allOption,
          ...this.apartments,
        ];
      }

      if (!this.value && this.apartments.length > 0) {
        this.input(this.apartments[0].id as string);
      }
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.getApartments();
  }
}
