




























import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class ConfirmationDialog extends Vue {
  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: true })
  readonly message!: string;

  @Emit('close')
  close(confirm: boolean) {
    return confirm;
  }
}
