import UserDto from '@/app/user/dto/user.dto';
import ApartmentDto from '@/app/apartments/dto/apartment.dto';
import Money from '@/app/core/dto/money.dto';

export interface ReservationChannelDto {
  id: number | string;
  name: string;
}

export interface ReservationGuestDto {
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  language?: string;
}

export interface ReservationPaymentDto {
  price?: number | Money;
  pricePaid?: boolean;
  prepayment?: number | Money;
  prepaymentPaid?: boolean;
  deposit?: number | Money;
  depositPaid?: boolean;
}

export enum PriceElementType {
  BasePrice = 'basePrice',
  CleaningFee = 'cleaningFee',
  Addon = 'addon',
  LongStayDiscount = 'longStayDiscount',
  Coupon = 'coupon',
  Commission = 'commission',
  ChannelCustom = 'channelCustom',
}

export interface ReservationPriceElementDto {
  id: number;
  name: string;
  type: PriceElementType;
  price: Money;
  quantity: number;
  tax: number;
  sortOrder: number;
  priceIncludedInId: number;
}

export interface ReservationSmoobuDto {
  id: number;
  apiKey?: string;
  guestAppUrl?: string;
}

export enum ReservationType {
  Reservation = 'reservation',
  Modification = 'modification of booking',
  Cancellation = 'cancellation',
}

export enum ReservationMessageType {
  Inbox = 1,
  Outbox = 2,
}

export interface ReservationMessageDto {
  id?: number | string;
  subject: string;
  text: string;
  html?: string;
  type?: ReservationMessageType;
}

export default interface ReservationDto {
  id?: string;
  apartment?: ApartmentDto | string;
  owner?: UserDto;
  host?: UserDto;
  type?: ReservationType;
  notice?: string;
  arrival: string;
  departure: string;
  checkIn?: string;
  checkOut?: string;
  adults?: number;
  children?: number;
  isBlocked?: boolean;
  channel: ReservationChannelDto | null;
  guest: ReservationGuestDto;
  payment?: ReservationPaymentDto;
  smoobu?: ReservationSmoobuDto;
  messages?: ReservationMessageDto[];
  priceElements?: ReservationPriceElementDto[];
}
