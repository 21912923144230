
































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ReservationChannelDto } from '../dto/reservation.dto';

@Component
export default class ReservationChannel extends Vue {
  @Prop({ type: Object, required: true })
  private channel!: ReservationChannelDto;
}
