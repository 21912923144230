import {
  Vue,
  Emit,
  Prop,
  Component,
} from 'vue-property-decorator';

@Component
export default class DatatableMixin extends Vue {
  private itemsPerPageOptions = [5, 10, 20, 35, 50];

  @Prop({ type: Array, required: true })
  items!: unknown[];

  @Prop({ type: Number, required: true })
  totalItems!: number;

  @Prop({ type: Number, default: 1 })
  page!: number;

  @Prop({ type: Number, default: 10 })
  itemsPerPage!: number;

  @Prop({ type: Boolean, default: false })
  loading!: boolean;

  @Emit('search')
  search(q: string) {
    return q;
  }

  @Emit('updatePage')
  updatePage(page: number) {
    return page;
  }

  @Emit('updateItemsPerPage')
  updateItemsPerPage(itemsPerPage: number) {
    return itemsPerPage;
  }
}
